@use 'src/styles/variables' as variables;

.article {
	display: flex;
	justify-content: space-between;
	gap: 4rem;
	padding: var(--space-inset-md);

	@media (max-width: variables.$breakpoint-xl) {
		gap: 2rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		gap: 1rem;
		padding: var(--space-inset-md) 0;
	}
}

.title.title.title {
	@media (max-width: variables.$breakpoint-md) {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

.textWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
}

.image {
	position: relative;
	width: 6.25rem;
	min-height: 6.25rem;
	max-height: 6.25rem;
}

.image,
.image img {
	border-radius: var(--border-radius-05);
}
