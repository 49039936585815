@use '../../../styles/variables' as variables;

.titleContainer {
	margin-bottom: var(--space-stack-xl);

	.title {
		font-weight: var(--font-weight-light);
	}
}

.grid {
	margin-bottom: var(--space-stack-xl);
}

.linkContainer {
	display: flex;
	justify-content: flex-end;

	@media (max-width: variables.$breakpoint-md) {
		justify-content: flex-start;
		padding-left: 0.5rem;
	}
}
