@use 'src/styles/variables' as variables;

.innerContainer {
	display: flex;
	flex-direction: column;
}

.content {
	z-index: var(--z-index-1);
	padding: 0 var(--space-inset-5xl);
	overflow-x: hidden;

	@media (max-width: variables.$breakpoint-lg) {
		padding: 0 var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-xl);
	}
}

.content,
.imageContainer {
	flex: 1;
}

.imageContainer {
	@media (max-width: variables.$breakpoint-md) {
		position: relative;
		min-height: var(--image-min-height-md);
	}
}

.menuVersionV3 {
	.contentContainer.contentContainer {
		@media (max-width: variables.$breakpoint-lg) {
			padding-top: 4rem;
		}
	}

	.imageContainer {
		@media (max-width: variables.$breakpoint-md) {
			position: relative;
			min-height: var(--image-height);
		}
	}

	.layout--right {
		.imageContainer {
			@media (max-width: variables.$breakpoint-lg) {
				min-height: var(--image-height);
			}
		}
	}
}

.inner {
	position: relative;
	padding: var(--space-inset-5xl) 0;
	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-3xl) 0;
	}
}

.linkButtons {
	display: flex;
	gap: var(--space-stack-lg) var(--space-inline-xl);

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
	}

	a {
		@media (max-width: variables.$breakpoint-md) {
			width: 100%;
		}
	}
}

.layout--full {
	.bubbleWrapper {
		position: absolute;
		top: 0;
		right: 0;
	}
	.brandlineContainer {
		display: flex;
		place-content: center;
		width: 100%;
	}

	.inner {
		display: flex;
		flex-wrap: wrap;
		place-content: center;
	}

	.linkButtons {
		display: flex;
		place-content: center;
		width: 100%;
	}

	.content {
		text-align: center;
	}

	.text {
		max-width: var(--text-max-width);
	}

	&.hasSubMenuV1 {
		.text {
			max-width: var(--text-max-width-sub-menu);
		}
	}

	&.textAlignLeft {
		.brandlineContainer {
			place-content: initial;
		}

		.inner {
			place-content: initial;
		}

		.linkButtons {
			place-content: initial;
		}

		.content {
			text-align: left;
		}
	}
}

.hasSubMenuV1 {
	.contentContainer {
		padding: 0;
	}

	.content {
		@media (min-width: variables.$breakpoint-lg) {
			padding: 0 var(--space-inset-3xl);
		}
	}

	.layout--full {
		.text {
			max-width: var(--text-max-width-sub-menu);
		}
	}

	.inner {
		padding: 4rem 0;

		@media (max-width: variables.$breakpoint-lg) {
			padding-top: 2rem;
		}

		@media (max-width: variables.$breakpoint-md) {
			padding: var(--space-inset-3xl) 0;
		}
	}

	.layout--right {
		.content {
			flex: 1 1 45rem;
			max-width: 45rem;
		}

		.imageContainer {
			flex: 1 0;
			img {
				@media (min-width: variables.$breakpoint-lg) {
					border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
				}
			}
		}
	}
}

.menuVersionV3,
.hasSubMenuV2,
.hasSubMenuV1 {
	.layout--right {
		.imageContainer {
			img {
				@media (min-width: variables.$breakpoint-lg) {
					border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
				}
			}
		}
	}
}

.layout--right {
	flex-direction: row-reverse;

	@media (max-width: variables.$breakpoint-lg) {
		flex-direction: column;
	}

	.content {
		@media (min-width: variables.$breakpoint-md) {
			z-index: var(--z-index-1);
		}
	}

	.imageContainer {
		position: relative;
		width: 50%;

		@media (max-width: variables.$breakpoint-lg) {
			width: 100%;
			min-height: var(--image-min-height-sm);
		}
	}
}

.container:not(.hasSubMenuV1) {
	@media (min-width: variables.$breakpoint-lg) {
		.innerContainer {
			height: 100%;
		}

		.contentContainer {
			height: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			min-height: 80vh;
		}
	}

	@media (min-width: variables.$breakpoint-max) {
		.content {
			min-height: 56rem;
		}
	}
}

.textShadow {
	.title {
		@media (min-width: variables.$breakpoint-lg) {
			text-shadow: 0 0 30px rgba(0, 0, 0, 0.65);
		}
	}

	.label,
	.text p {
		@media (min-width: variables.$breakpoint-lg) {
			text-shadow: 0 0 30px rgba(0, 0, 0, 0.45);
		}
	}
}
