@use 'src/styles/variables' as variables;

.container {
	@media (max-width: variables.$breakpoint-lg) {
		top: var(--global-menu-height);
	}
}

.innerContainer {
	display: flex;

	@media (max-width: variables.$breakpoint-lg) {
		flex-direction: column;
	}
}

.hasSubMenu {
	justify-content: center;
	margin: 0 auto;
	margin-bottom: var(--space-stack-3xl);
	max-width: var(--global-max-width);

	@media (max-width: variables.$breakpoint-lg) {
		margin: 0;
		padding: 0;
	}
}

.hasSubMenuV2 {
	margin-top: 1.5rem;

	.innerContainer,
	.bottomSections {
		padding: 0 var(--space-inset-7xl);

		@media (max-width: variables.$breakpoint-xl) {
			margin-top: 0;
			padding: 0 var(--space-inset-3xl);
		}

		@media (max-width: variables.$breakpoint-md) {
			padding: 0;
		}
	}
}

.contentColumn {
	width: 100%;
}

// Old Sub Menu
.hasSubMenuV1 {
	margin-top: 2rem;
	padding: var(--space-inset-3xl) var(--space-inset-lg) 0 var(--space-inset-lg);

	@media (max-width: variables.$breakpoint-lg) {
		margin-top: 0;
		padding: 0;
	}
}

.menuVersionV3 {
	&.hasSubMenuV1 {
		margin-top: 5rem;

		@media (max-width: variables.$breakpoint-xl) {
			margin-top: 0;
		}
	}
}

.subMenuColumn {
	margin-right: var(--space-inline-2xl);
	width: 20%;
	min-width: 20%;

	@media (max-width: variables.$breakpoint-max) {
		margin-right: var(--space-inline-xl);
	}

	@media (min-width: variables.$breakpoint-lg) {
		width: 30%;
	}

	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.subMenu {
	position: sticky;
	top: 7.5rem;
	transition: all ease 0.3s;
}

.onScroll {
	top: 7rem;
}

.mobileMenu {
	@media (min-width: variables.$breakpoint-lg) {
		display: none;
	}

	@media (max-width: variables.$breakpoint-lg) {
		position: fixed;
	}
}
