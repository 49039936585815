.titleWrapper {
	border-radius: var(--border-radius-1);
	padding: var(--space-inset-md);
}

.articlesWrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
