@use 'src/styles/variables' as variables;

.content {
	padding: var(--space-inset-xl) var(--space-inset-xs) 0 var(--space-inset-xs);
}

.image {
	border-radius: var(--border-radius-card);
	height: 22.75rem;
	overflow: hidden;

	@media (max-width: variables.$breakpoint-md) {
		height: 18.75rem;
	}
}
