@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.container {
	color: var(--text-dark);

	.integrationGrid {
		@media screen and (max-width: variables.$breakpoint-sm) {
			row-gap: var(--space-inset-2xl);
		}
		@media screen and (min-width: variables.$breakpoint-sm) and (max-width: variables.$breakpoint-md) {
			row-gap: var(--space-inset-3xl);
		}
		@media screen and (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-lg) {
			row-gap: var(--space-inset-3xl);
		}
		@media screen and (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-xl) {
			column-gap: var(--space-inset-3xl);
		}
		@media screen and (min-width: variables.$breakpoint-xl) {
			column-gap: var(--space-inset-5xl);
		}
	}

	.mainContent {
		.header {
			display: flex;
			column-gap: var(--space-stack-xl);
			align-items: center;

			@media screen and (min-width: variables.$breakpoint-sm) {
				flex-direction: row;
			}

			.categoryList {
				display: flex;
				flex-basis: 100%;
				align-items: center;

				svg {
					margin-right: var(--space-inset-xs);
				}

				a {
					color: var(--text-dark);
					font-weight: var(--font-weight-light);
					font-size: var(--font-size-small);
					text-decoration: underline;

					&:hover {
						color: var(--action-color);
					}
				}

				a::after {
					content: ',';
				}
				a:last-of-type::after {
					content: '';
				}
			}

			.rating {
				display: flex;
				flex-basis: 49%;
				align-items: center;
				gap: var(--space-inset-xs);
				margin-top: var(--space-stack-xs);
				margin-bottom: var(--space-stack-lg);
				font-size: var(--font-size-small);
			}
		}

		.sliderVideo,
		.sliderImage {
			position: relative !important;
			border-radius: var(--border-radius-1);
			aspect-ratio: 16/9;
			object-fit: cover;
		}
	}

	.sidebar {
		@media (min-width: variables.$breakpoint-lg) {
			margin-top: var(--space-inset-6xl);
		}
	}
}

.integrationPageHeading {
	display: flex;
	column-gap: var(--space-inset-xl);
	row-gap: var(--space-inset-xs);
	flex-grow: 1;
	flex-direction: column;
	word-break: break-word;
}

.insightsBlock {
	display: flex;
	flex-wrap: wrap;
	gap: var(--space-inset-xs);
}

.summary {
	margin-bottom: var(--space-stack-3xl);

	p {
		font-weight: var(--font-weight-bold) !important;
	}
}

.header {
	flex-direction: row;
	margin-bottom: var(--space-stack-xl);
	@media screen and (max-width: variables.$breakpoint-md) {
		row-gap: var(--space-inset-xs);
		flex-direction: column;
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		.mainContent {
			.imageContainer {
				height: var(--image-min-height-sm);
			}
		}
	}
}
