@use 'src/styles/variables' as variables;

.container {
	display: flex;
	height: 100%;

	@media (max-width: variables.$breakpoint-lg) {
		flex-direction: column;
	}
}

.image {
	position: relative;
	flex: 1;
	width: 35.1875rem;
	min-height: 30rem;

	@media (max-width: variables.$breakpoint-lg) {
		width: 100%;
	}

	@media (max-width: variables.$breakpoint-md) {
		min-height: var(--image-height);
	}
}

.image,
.image img {
	border-radius: var(--border-radius-card) 0 0 var(--border-radius-card);

	@media (max-width: variables.$breakpoint-lg) {
		border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
	}
}

.textContent {
	flex: 1;
	border-radius: 0 var(--border-radius-card) var(--border-radius-card) 0;
	padding: var(--space-inset-2xl);

	@media (max-width: variables.$breakpoint-lg) {
		border-radius: 0 0 var(--border-radius-card) var(--border-radius-card);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-xl) var(--space-inset-lg);
	}
}
